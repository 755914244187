import React from 'react';
import About from '../components/About';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const AboutPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>kimcc's portfolio | about</title>
        <link rel="canonical" href="https://kimcc.dev/about" />
      </Helmet>
      <About content={data.mdx} />
    </>
  );
}

// Query the about page
export const query = graphql`
  query {
    mdx(frontmatter: {title: {eq: "About"}}) {
      frontmatter {
        title
        description
        skills
        resume
        linkedin
      }
    }
  }
`;

export default AboutPage;