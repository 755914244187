import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import arrow from '../assets/arrow.svg';
import pdf from '../assets/Kimberly_Chang_Resume.pdf';

const SkillsContainer =  styled.section`
  margin-top: 3em;
`
// Overall container
const Container = styled.div`
  max-width: 1640px;
  margin: 0 auto;
  padding: 16px;

  @media screen and (min-width: 768px) {
    padding: 40px;
  }
`;

const Navigation = styled.nav`
  flex: 1 1 auto;
  position: absolute;
  z-index: 99;
  margin: 0px;

  @media screen and (min-width: 768px) {
    margin: -5px 0 0 0;
    position: fixed;
  }
`;

const About = ({ content }) => {
  const { frontmatter } = content;

  return (
    <Container>
    <Navigation>
      <Link to="/" className="back-link">
      <img src={arrow} alt="" height="18px" width="18px"/>Back
      </Link>
    </Navigation>
    <section className="section-container">
      <h2>{frontmatter.title}</h2>
      <div>
        <p>{frontmatter.description}</p>
        <h5>Skills/languages</h5>
        <p>{frontmatter.skills}</p>
        <ul>
          <li>
            <a href="mailto:kimccdev@protonmail.com" target="_blank" rel="noreferrer">
            Email
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/-kimberlyc" target="_blank" rel="noreferrer">
            LinkedIn</a>
          </li>
          <li>
            <a href={pdf} target="_blank" rel="noreferrer">
              Resume</a>
          </li>
        </ul>
      </div>
      <SkillsContainer>
        <p>
          This website was built with <a href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">
          Gatsby</a> and <a href="https://brm.io/matter-js" target="_blank" rel="noreferrer">
          matter.js
          </a>
        </p>
      </SkillsContainer>
    </section>
    </Container>
  );
}

export default About;